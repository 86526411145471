<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
        <el-form  :model="table.where" class="ele-form-search d-flexspabet mt-20"
                @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
            <div>
            <el-button @click="showEdit=true" class="ele-btn-icon addbtn " size="small" v-if="permission.includes('sys:storeBackground:add')">添加背景图</el-button>
            </div>
        </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row :stripe=true class="mt-20">
        <template slot-scope="{index}">
        <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left" show-overflow-tooltip/>
          <el-table-column prop="type" label="显示端"  show-overflow-tooltip min-width="80">
            <template slot-scope="scope">
              <span>{{scope.row.type==1?'商家版-超市':'商家版-叫餐'}}</span>
            </template>
          </el-table-column>
           <el-table-column prop="images" label="背景缩略图"  show-overflow-tooltip>
            <template slot-scope="{row}">
							<span v-for="(option,index) in row.images" :key="index" style="margin-right: 10px;">
								<el-image style="width:25px;height:25px;" :src="option" fit="cover" :preview-src-list="[option]">
									<div slot="error" class="image-slot">
										<i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</span>
            </template>
          </el-table-column>
          <el-table-column label="更新时间"  show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.update_time*1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column label="操作" width="220px" align="center" :resizable="false"  fixed="right">
            <template slot-scope="{row}">
              <el-link @click="handleEdit(row)" icon="el-icon-view" type="primary" :underline="false" v-if="permission.includes('sys:storeBackground:edit')">编辑</el-link>
              <el-link @click="remove(row)" icon="el-icon-edit" type="primary" :underline="false" v-if="permission.includes('sys:storeBackground:delete')">删除</el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 查看商家详情 -->
    <el-dialog v-dialogDrag :title="editForm.id?'编辑商店默认背景图':'添加商店默认背景图'" :visible.sync="showEdit " @closed="editForm={}" :destroy-on-close="true" :lock-scroll="false">
      <el-card  shadow="never">
          <el-form :model="editForm" ref="editForm" label-width="150px" :rules="editRules">
            <el-form-item label="请选择店铺类型：" prop="type">
            <el-select v-model="editForm.type" placeholder="请选择类型">
                <el-option label="超市" :value="1"></el-option>
                <el-option label="叫餐" :value="2"></el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="请添加默认背景图：(建议图片长宽比2:1)" prop="images">
							<uploadImage :limit="6" v-model="editForm.images"></uploadImage>
            </el-form-item>
        </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import uploadImage from '@/components/uploadImage'

export default {
  name: "SysUser",
  data() {
    return {
		table: {url: '/StoreBackground/index', where: {}},  // 表格配置
		choose: [],  // 表格选中数据
		showEdit: false,  // 是否显示表单弹窗
		editForm: {},  // 表单数据
		editRules: {  // 表单验证规则
        type: [
            {required: true, message: '请选择显示端', trigger: 'change'}
        ],
        images: [
            {required: true, message: '请选择图片', trigger: 'blur'}
        ],
      },
		provArr:[],
		cityArr:[],
		districtArr:[],
		checkForm:{},
		recordsList:[],
		startTime: '',
		endTime: '',
		driverData:{},
		dialogTableVisibleEdit:false,
		dialogTableVisibleCheck:false,
		dialogTableVisibleRecords:false,
		pageSize:10,
		pageNumber:1,
		recordsTotal:1000,
		recordsShow:false,
		recordsId:'',
		loading:true,
		pid:'',
		cid:'',
		aid:''
    }
  },
  created(){


    this.$http.get('/common/province_list').then(res=>{
      let data=JSON.parse(res.data)
      this.provArr=data
    })

  },
  computed: {
    ...mapGetters(["permission"]),
  },
  components: {uploadImage},
  mounted() {
  },
  methods: {
    dropClick(command,row){
      if(command==='edit'){// 查看用户信息
        this.edit()
      }else if(command==='remove'){//删除
        this.remove()
      }else if(command==='out_list'){//出车记录
        this.handleCarRecords(row)
      }else if(command==='check_list'){//检测记录
        this.handleRecords(row)
      }else if(command==='edit'){//修改用户信息
        this.edit(row)
      }else if(command==='forlimit'){//限号
        this.forlimit(row)
      }if(command==='blackData'){//拉入黑名单
        this.blackData(row)
      }
    },

    /**
     *选择省
     **/
    handleChangeProv(e){
      /** 获取被选省省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table.where.cid=''
        this.table.where.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table.where.aid=''

      })
			}else{
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
    },
     handleChangeProv1(e){
      /** 获取被选省省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.editForm.cid=''
        this.editForm.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity1(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.editForm.aid=''

      })
			}else{
				this.editForm.pid = ''
				this.editForm.aid = ''
			}
    },

    /**
     *修改
     **/
    handleEdit(row){
      this.showEdit=true
      this.editForm = row;
    },


    /**
     * 保存编辑
     */
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({lock: true});
          this.$http.post('/StoreBackground/edit', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * 刪除(批量刪除)
     */
    remove(row) {
      //console.log(row)
      if (!row) {  // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
        let ids = this.choose.map(d => d.id);
        this.$confirm('确定要删除选中的背景图吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/StoreBackground/delete', {id: ids}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        }).catch(() => 0);
      } else {  // 单个删除
			this.$confirm('确定要删除选中的背景图吗?', '提示', {type: 'warning'}).then(() => {
        const loading = this.$loading({lock: true});
					this.$http.post('/StoreBackground/delete', {id:row.id}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({type: 'success', message: res.data.msg});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
      }
    },

  }
}
</script>

<style scoped>
.ele-block .el-upload-dragger {
  width: 100%;
}



</style>